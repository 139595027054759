.sf-menu,
.sf-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sf-menu {
  line-height: 1;
}

.sf-menu ul {
  position: absolute;
  top: -999em;
  width: 10em;
}

.sf-menu ul li {
  width: 100%;
}

.sf-menu li:hover {
  visibility: inherit;
}

.sf-menu li {
  float: left;
  position: relative;
}

.sf-menu a {
  display: block;
  position: relative;
}

.sf-menu li:hover ul,
.sf-menu li.sfHover ul {
  left: 0;
  top: 2.5em;
  z-index: 99;
}

ul.sf-menu li:hover li ul,
ul.sf-menu li.sfHover li ul {
  top: -999em;
}

ul.sf-menu li li:hover ul,
ul.sf-menu li li.sfHover ul {
  left: 10em;
  top: 0;
}

ul.sf-menu li li:hover li ul,
ul.sf-menu li li.sfHover li ul {
  top: -999em;
}

ul.sf-menu li li li:hover ul,
ul.sf-menu li li li.sfHover ul {
  left: 10em;
  top: 0;
}

.sf-menu {
  float: left;
}

.sf-menu a {
  border-left: 1px solid #fff;
  padding: 0.75em 14px;
  text-decoration: none;
}

.sf-menu a,
.sf-menu a:visited {
  color: #275b90;
}

.sf-menu li {
  background: #dadada;
}

.sf-menu li li {
  background: #aabde6;
}

.sf-menu li li a {
}

.sf-menu li li li {
  background: #9aaedb;
}

.sf-menu li:hover,
.sf-menu li.sfHover,
.sf-menu a:focus,
.sf-menu a:hover,
.sf-menu a:active {
  background: #cfdeff;
  outline: 0;
}

.sf-menu a.sf-with-ul {
  padding-right: 2.25em;
  min-width: 1px;
}

.sf-sub-indicator {
  position: absolute;
  display: block;
  right: 0.75em;
  top: 1.05em;
  width: 10px;
  height: 10px;
  text-indent: -999em;
  overflow: hidden;
}

a > .sf-sub-indicator {
  top: 0.8em;
  background-position: 0 -100px;
}

a:focus > .sf-sub-indicator,
a:hover > .sf-sub-indicator,
a:active > .sf-sub-indicator,
li:hover > a > .sf-sub-indicator,
li.sfHover > a > .sf-sub-indicator {
  background-position: -10px -100px;
}

.sf-menu ul .sf-sub-indicator {
  background-position: -10px 0;
}

.sf-menu ul a > .sf-sub-indicator {
  background-position: 0 0;
}

.sf-menu ul a:focus > .sf-sub-indicator,
.sf-menu ul a:hover > .sf-sub-indicator,
.sf-menu ul a:active > .sf-sub-indicator,
.sf-menu ul li:hover > a > .sf-sub-indicator,
.sf-menu ul li.sfHover > a > .sf-sub-indicator {
  background-position: -10px 0;
}

.sf-shadow ul {
  padding: 0 8px 9px 0;
  -moz-border-radius-bottomleft: 17px;
  -moz-border-radius-topright: 17px;
  -webkit-border-top-right-radius: 17px;
  -webkit-border-bottom-left-radius: 17px;
}

.sf-shadow ul.sf-shadow-off {
  background: transparent;
}

.container_24 {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
}

.grid_1,
.grid_2,
.grid_3,
.grid_4,
.grid_5,
.grid_6,
.grid_7,
.grid_8,
.grid_9,
.grid_10,
.grid_11,
.grid_12,
.grid_13,
.grid_14,
.grid_15,
.grid_16,
.grid_17,
.grid_18,
.grid_19,
.grid_20,
.grid_21,
.grid_22,
.grid_23,
.grid_24 {
  display: inline;
  float: left;
  margin-left: 5px;
  margin-right: 5px;
}

.push_1,
.pull_1,
.push_2,
.pull_2,
.push_3,
.pull_3,
.push_4,
.pull_4,
.push_5,
.pull_5,
.push_6,
.pull_6,
.push_7,
.pull_7,
.push_8,
.pull_8,
.push_9,
.pull_9,
.push_10,
.pull_10,
.push_11,
.pull_11,
.push_12,
.pull_12,
.push_13,
.pull_13,
.push_14,
.pull_14,
.push_15,
.pull_15,
.push_16,
.pull_16,
.push_17,
.pull_17,
.push_18,
.pull_18,
.push_19,
.pull_19,
.push_20,
.pull_20,
.push_21,
.pull_21,
.push_22,
.pull_22,
.push_23,
.pull_23 {
  position: relative;
}

.alpha {
  margin-left: 0;
}

.omega {
  margin-right: 0;
}

.container_24 .grid_1 {
  width: 30px;
}

.container_24 .grid_2 {
  width: 70px;
}

.container_24 .grid_3 {
  width: 110px;
}

.container_24 .grid_4 {
  width: 150px;
}

.container_24 .grid_5 {
  width: 190px;
}

.container_24 .grid_6 {
  width: 230px;
}

.container_24 .grid_7 {
  width: 270px;
}

.container_24 .grid_8 {
  width: 310px;
}

.container_24 .grid_9 {
  width: 350px;
}

.container_24 .grid_10 {
  width: 390px;
}

.container_24 .grid_11 {
  width: 430px;
}

.container_24 .grid_12 {
  width: 470px;
}

.container_24 .grid_13 {
  width: 510px;
}

.container_24 .grid_14 {
  width: 550px;
}

.container_24 .grid_15 {
  width: 590px;
}

.container_24 .grid_16 {
  width: 630px;
}

.container_24 .grid_17 {
  width: 670px;
}

.container_24 .grid_18 {
  width: 710px;
}

.container_24 .grid_19 {
  width: 750px;
}

.container_24 .grid_20 {
  width: 790px;
}

.container_24 .grid_21 {
  width: 830px;
}

.container_24 .grid_22 {
  width: 870px;
}

.container_24 .grid_23 {
  width: 910px;
}

.container_24 .grid_24 {
  width: 950px;
}

.container_24 .prefix_1 {
  padding-left: 40px;
}

.container_24 .prefix_2 {
  padding-left: 80px;
}

.container_24 .prefix_3 {
  padding-left: 120px;
}

.container_24 .prefix_4 {
  padding-left: 160px;
}

.container_24 .prefix_5 {
  padding-left: 200px;
}

.container_24 .prefix_6 {
  padding-left: 240px;
}

.container_24 .prefix_7 {
  padding-left: 280px;
}

.container_24 .prefix_8 {
  padding-left: 320px;
}

.container_24 .prefix_9 {
  padding-left: 360px;
}

.container_24 .prefix_10 {
  padding-left: 400px;
}

.container_24 .prefix_11 {
  padding-left: 440px;
}

.container_24 .prefix_12 {
  padding-left: 480px;
}

.container_24 .prefix_13 {
  padding-left: 520px;
}

.container_24 .prefix_14 {
  padding-left: 560px;
}

.container_24 .prefix_15 {
  padding-left: 600px;
}

.container_24 .prefix_16 {
  padding-left: 640px;
}

.container_24 .prefix_17 {
  padding-left: 680px;
}

.container_24 .prefix_18 {
  padding-left: 720px;
}

.container_24 .prefix_19 {
  padding-left: 760px;
}

.container_24 .prefix_20 {
  padding-left: 800px;
}

.container_24 .prefix_21 {
  padding-left: 840px;
}

.container_24 .prefix_22 {
  padding-left: 880px;
}

.container_24 .prefix_23 {
  padding-left: 920px;
}

.container_24 .suffix_1 {
  padding-right: 40px;
}

.container_24 .suffix_2 {
  padding-right: 80px;
}

.container_24 .suffix_3 {
  padding-right: 120px;
}

.container_24 .suffix_4 {
  padding-right: 160px;
}

.container_24 .suffix_5 {
  padding-right: 200px;
}

.container_24 .suffix_6 {
  padding-right: 240px;
}

.container_24 .suffix_7 {
  padding-right: 280px;
}

.container_24 .suffix_8 {
  padding-right: 320px;
}

.container_24 .suffix_9 {
  padding-right: 360px;
}

.container_24 .suffix_10 {
  padding-right: 400px;
}

.container_24 .suffix_11 {
  padding-right: 440px;
}

.container_24 .suffix_12 {
  padding-right: 480px;
}

.container_24 .suffix_13 {
  padding-right: 520px;
}

.container_24 .suffix_14 {
  padding-right: 560px;
}

.container_24 .suffix_15 {
  padding-right: 600px;
}

.container_24 .suffix_16 {
  padding-right: 640px;
}

.container_24 .suffix_17 {
  padding-right: 680px;
}

.container_24 .suffix_18 {
  padding-right: 720px;
}

.container_24 .suffix_19 {
  padding-right: 760px;
}

.container_24 .suffix_20 {
  padding-right: 800px;
}

.container_24 .suffix_21 {
  padding-right: 840px;
}

.container_24 .suffix_22 {
  padding-right: 880px;
}

.container_24 .suffix_23 {
  padding-right: 920px;
}

.container_24 .push_1 {
  left: 40px;
}

.container_24 .push_2 {
  left: 80px;
}

.container_24 .push_3 {
  left: 120px;
}

.container_24 .push_4 {
  left: 160px;
}

.container_24 .push_5 {
  left: 200px;
}

.container_24 .push_6 {
  left: 240px;
}

.container_24 .push_7 {
  left: 280px;
}

.container_24 .push_8 {
  left: 320px;
}

.container_24 .push_9 {
  left: 360px;
}

.container_24 .push_10 {
  left: 400px;
}

.container_24 .push_11 {
  left: 440px;
}

.container_24 .push_12 {
  left: 480px;
}

.container_24 .push_13 {
  left: 520px;
}

.container_24 .push_14 {
  left: 560px;
}

.container_24 .push_15 {
  left: 600px;
}

.container_24 .push_16 {
  left: 640px;
}

.container_24 .push_17 {
  left: 680px;
}

.container_24 .push_18 {
  left: 720px;
}

.container_24 .push_19 {
  left: 760px;
}

.container_24 .push_20 {
  left: 800px;
}

.container_24 .push_21 {
  left: 840px;
}

.container_24 .push_22 {
  left: 880px;
}

.container_24 .push_23 {
  left: 920px;
}

.container_24 .pull_1 {
  left: -40px;
}

.container_24 .pull_2 {
  left: -80px;
}

.container_24 .pull_3 {
  left: -120px;
}

.container_24 .pull_4 {
  left: -160px;
}

.container_24 .pull_5 {
  left: -200px;
}

.container_24 .pull_6 {
  left: -240px;
}

.container_24 .pull_7 {
  left: -280px;
}

.container_24 .pull_8 {
  left: -320px;
}

.container_24 .pull_9 {
  left: -360px;
}

.container_24 .pull_10 {
  left: -400px;
}

.container_24 .pull_11 {
  left: -440px;
}

.container_24 .pull_12 {
  left: -480px;
}

.container_24 .pull_13 {
  left: -520px;
}

.container_24 .pull_14 {
  left: -560px;
}

.container_24 .pull_15 {
  left: -600px;
}

.container_24 .pull_16 {
  left: -640px;
}

.container_24 .pull_17 {
  left: -680px;
}

.container_24 .pull_18 {
  left: -720px;
}

.container_24 .pull_19 {
  left: -760px;
}

.container_24 .pull_20 {
  left: -800px;
}

.container_24 .pull_21 {
  left: -840px;
}

.container_24 .pull_22 {
  left: -880px;
}

.container_24 .pull_23 {
  left: -920px;
}

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

input:invalid,
textarea:invalid {
  border-radius: 1px;
  -moz-box-shadow: 0px 0px 5px red;
  -webkit-box-shadow: 0px 0px 5px red;
  box-shadow: 0px 0px 5px red;
}

.no-boxshadow input:invalid,
.no-boxshadow textarea:invalid {
  background-color: #f0dddd;
}

::-moz-selection {
  background: #555;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #555;
  color: #fff;
  text-shadow: none;
}

a:link {
  -webkit-tap-highlight-color: #555;
}

.ie7 img {
  -ms-interpolation-mode: bicubic;
}

.ir {
  display: block;
  text-indent: -999em;
  overflow: hidden;
  background-repeat: no-repeat;
}

.hidden {
  display: none;
  visibility: hidden;
}

.visuallyhidden {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

.ez-wr:after,
.ez-box:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.ez-wr,
.ez-box,
.ez-last {
  display: inline-block;
  min-height: 0;
}

* html .ez-wr,
* html .ez-box,
* html .ez-last {
  height: 1%;
}

.ez-wr,
.ez-box,
.ez-last {
  display: block;
}

.ez-oh {
  overflow: hidden;
}

* html .ez-oh {
  overflow: visible;
}

.ez-oa {
  overflow: auto;
}

.ez-dt {
  display: table;
}

.ez-it {
  display: inline-table;
}

.ez-tc {
  display: table-cell;
}

.ez-ib {
  display: inline-block;
}

.ez-fl {
  float: left;
}

* html .ez-fl {
  margin-right: -3px;
}

.ez-fr {
  float: right;
}

* html .ez-fr {
  margin-left: -3px;
}

.ez-25 {
  width: 25%;
}

.ez-33 {
  width: 33.33%;
}

.ez-50 {
  width: 50%;
}

.ez-66 {
  width: 66.66%;
}

.ez-75 {
  width: 75%;
}

.ez-negmr {
  margin-right: -1px;
}

* html .ez-negmr {
  margin-right: -4px;
}

.ez-negmx {
  margin-right: -1px;
}

.ez-negml {
  margin-left: -1px;
}

* html .ez-negml {
  margin-left: -4px;
}

th,
td {
  padding: 0.14px 0.5em 0.14px 0.5em;
}

label {
  white-space: nowrap;
}

label,
b,
th {
  font-weight: bold;
}

thead th {
  text-align: center;
  border-bottom: 1px solid #444;
}

form,
table {
  padding: 5px 10px 5px 10px;
}

code {
  padding: 3px 5px;
  font-family: Andale Mono, monospace;
  font-size: 0.9em;
}

blockquote {
  background: #ccc;
  border-left: 30px transparent;
  border-right: 30px transparent;
}

fieldset {
  border: 1px solid #dedede;
  padding: 6px;
}

legend {
  font-weight: bold;
}

input:focus,
textarea:focus,
select:focus {
  background: #fff;
  border: 1px solid #999;
}

#container,
#header,
#page,
#content,
#statusbar,
#top,
#footer,
#wrapper {
  display: block;
  line-height: 170%;
}

#container {
  margin: 0 auto;
  padding: 0;
}

#wrapper {
  margin: 0 auto;
}

#wrapper {
  background-color: #fff;
  padding: 0px 5px 5px 5px;
}

#top {
  height: 28px;
  width: 100%;
  position: relative;
}

#statusbar {
  height: 80px;
  padding: 5px 0px 20px 0px;
}

#statusbar ul {
  float: right;
  list-style: none outside none;
  margin: 32px 0 0;
  font-size: 1.385em;
}

#statusbar .sf-menu li,
#statusbar .sf-menu li a {
  border-top: 3px solid #7f2400;
  color: #7f2400;
  background-color: transparent;
}

#statusbar .sf-menu li.current,
#statusbar .sf-menu li.current a {
  border-top: 3px solid #0753ea;
  color: #0753ea;
}

#statusbar .sf-menu li:hover,
#statusbar .sf-menu li a:hover {
  border-top: 3px solid #0753ea;
  color: #0753ea;
}

.sf-menu li {
  margin: 0 1px;
}

.sf-menu a {
  border-left: 0px solid #fff;
  padding: 0.75em;
}

#footer {
  margin-top: 30px;
  padding: 5px 0;
  height: 100px;
  border-bottom: 1px solid #aaa;
  border-top: 1px solid #aaa;
}

#header {
}

#logo a {
  border: 0 none;
  height: 60px;
  overflow: hidden;
  text-indent: -9999px;
  width: 207px;
  display: block;
  float: left;
  margin: 20px 0 0 10px;
}

#right_sidebar {
  width: 160px;
  float: right;
  display: none;
}

#left_sidebar {
  width: 160px;
  float: left;
  display: none;
}

#content {
  float: left;
}

.auth_navbar {
  top: 0px;
  float: right;
  padding: 3px 10px 3px 10px;
}

div.flash {
  font-weight: bold;
  display: none;
  position: absolute;
  padding: 10px;
  top: 20px;
  right: 5px;
  min-width: 280px;
  opacity: 0.75;
  margin: 0px 0px 10px 10px;
  color: #fff;
  vertical-align: middle;
  cursor: pointer;
  background: #09c;
  border: 2px solid #fff;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  z-index: 2;
}

div.error {
  background-color: #ff5e99;
  color: white;
  padding: 3px;
}

.mobile_wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 470px;
}

a,
a:visited {
  color: #0753ea;
  text-decoration: none;
}

a:hover {
  background-color: #fff;
  color: #7f2400;
}

.highlightheading {
  background-color: #444;
  color: #fff;
  padding-left: 5px;
}

.w2p_fl {
  font-size: 1.153em;
  vertical-align: middle;
  color: #44464b;
}

a.button_large {
  border: 1px solid #032c7f;
  color: #fff;
  background-color: #0753ea;
  -moz-border-radius: 4px;
  padding: 10px;
  font-size: 120%;
  font-weight: bold;
}

.darkbluebox {
  background-color: #032c7f;
  color: #ffffff;
}

.darkgreybox {
  background-color: #444444;
  color: #fff;
}

.superheading {
  font-size: 1.5em;
  font-weight: bold;
  margin: 20px 0 13px;
  padding: 20px 10px;
  text-align: center;
}

.firstdescription {
  font-size: 1.5em;
  border: 10px solid #426389;
  color: #426389;
  margin: 20px 0 13px;
  padding: 20px 10px;
}

.specialword {
  color: #ff6600;
  font-weight: bold;
}

.register_link {
  margin: 15px 0 15px;
}

#auth_user_password_two__row td {
  font-size: 1.153em;
  vertical-align: middle;
  color: #195f7f;
}

.case_name {
}

.case_description {
  font-size: 120%;
  line-height: 150%;
  border-bottom: 1.8px solid #444;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.case_description h1,
.case_description h2,
.case_description h3,
.case_description h4,
.case_description h5,
.case_description h6 {
  font-size: 140%;
  line-height: 170%;
}

.case_description ul {
  margin: 0 0 5px 0;
}

.case_description p {
  text-indent: 0;
}

.case_wrapper {
  margin-top: 5px;
}

.case_list {
  border-bottom: 1px dotted #999;
  float: left;
  font-size: 150%;
  font-weight: bold;
  width: 95%;
  padding: 11px 0;
}

.case_list_name {
  float: left;
  width: 100%;
}

.case_list_editlink {
  float: right;
}

.case_list a.male {
  color: #07c;
  background-color: transparent;
}

.case_list a.female {
  color: #fb607f;
  background-color: transparent;
}

.post_summary {
  float: left;
  width: 14%;
  min-height: 50px;
}

.post_list_name {
  float: left;
  width: 85%;
}

.post_viewed,
.post_replied {
  float: left;
  text-align: center;
  font-size: 0.7em;
  margin: 0 5px;
}

.post-taglist {
  float: left;
}

.posttitle {
  border-bottom: 1px solid #aaa;
  color: #000;
}

.post_starts {
  font-size: 0.692em;
  float: right;
}

.post-user-signature {
  clear: both;
  height: 50px;
  padding-left: 5px;
  padding-top: 2px;
  text-align: left;
  vertical-align: top;
  width: 220px;
}

.post_by,
.post_on {
  padding: 1px 5px;
}

.reply_post {
  margin: 15px 0;
}

.reply_post a {
  font-size: 1.231em;
}

.post_comment {
  margin: 15px 0;
}

.comment_by {
  background-color: #f2f2f2;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}

.comment_body p {
  margin-bottom: 0;
  text-indent: 0;
}

.pager {
  margin-bottom: 20px;
  margin-top: 20px;
  float: right;
}

.page-numbers {
  border: 1px solid #ccc;
  color: #808185;
  margin-right: 3px;
  padding: 4px 4px 3px;
  font-size: 120%;
}

.loading {
  width: 24px;
  height: 24px;
  margin: 24px;
}

.post-tag,
a.post-tag {
  background-color: #e7ecec;
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  color: #555555;
  text-decoration: none;
  white-space: nowrap;
  margin: 2px 5px 2px 0;
  padding: 3px 4px;
  font-size: 0.692em;
}

.showsmallfortune {
  background-color: #636363;
  color: #fff;
}

.metal {
  background-color: #c68e17;
  color: #fff;
}

.water {
  background-color: #0753ea;
  color: #fff;
}

.wood {
  background-color: #278a2a;
  color: #fff;
}

.fire {
  background-color: #ee1417;
  color: #fff;
}

.soil {
  background-color: #a63800;
  color: #fff;
}

.likemyside .myside {
  color: #f00;
}

.likemyside .otherside {
  color: #00f;
}

.likeotherside .myside {
  color: #00f;
}

.likeotherside .otherside {
  color: #f00;
}

.middleside {
  color: #00f;
}

.hasstamp .likemyside .middleside,
.nostamp .likeotherside .middleside {
  color: #f00;
}

.baziboxgrid {
  font-size: 1.385em;
}

.toprelationdiv,
.bottomrelationdiv {
  font-size: 14px;
}

.fdetailword {
  font-size: 1.23em;
}

.fortunecolumn {
  font-size: 0.923em;
}

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.textleft {
  text-align: left;
}

.textright {
  text-align: right;
}

.textcenter {
  text-align: center;
}

.left {
  float: left;
}

.right {
  float: right;
}

.bazicontroltop {
  min-width: 100px;
  width: auto !important;
}

.baziboxgrid {
  text-align: center;
  position: relative;
  /* min-width: 375px; */
  width: auto !important;
  width: 470px;
}

.baziwrapper {
  width: 100%;
  /* min-width: 375px; */
  width: auto !important;
  width: 470px;
}

.personalinfowrap {
  margin-left: 20px;
  margin-bottom: 10px;
}

.boxdetail {
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  width: 24.2%;
  position: relative;
  float: left;
  height: 3em;
  font-weight: normal;
}

.detailright {
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0px;
}

.detailword {
  position: relative;
  top: 15px;
}

.smalldetail {
  height: 33.3%;
}

.toprelationdiv {
  clear: both;
  position: relative;
  width: 95%;
}

.bottomrelationdiv {
  clear: both;
  position: relative;
  top: -13px;
  width: 95%;
}

.relationrow {
  position: relative;
  float: left;
  border-width: 1px;
  height: 5px;
}

.left_border {
  border-left-style: solid;
}

.right_border {
  border-right-style: solid;
}

.top_border {
  border-top-style: solid;
}

.bottom_border {
  border-bottom-style: solid;
  margin-top: 15px;
}

.relationword {
  display: block;
  padding-top: 5px;
}

.trelationword {
  display: block;
}

.cellsize_12 {
  width: 12%;
}

.cellsize_24 {
  width: 24%;
}

.cellsize_36 {
  width: 36%;
}

.cellsize_48 {
  width: 48%;
}

.cellsize_60 {
  width: 60%;
}

.cellsize_72 {
  width: 72%;
}

.cellsize_33 {
  width: 33.3%;
}

.cellsize_66 {
  width: 66.7%;
}

.cellsize_50 {
  width: 50%;
}

.baziprefix_12 {
  margin-left: 12%;
}

.baziprefix_24 {
  margin-left: 24%;
}

.baziprefix_36 {
  margin-left: 36%;
}

.baziprefix_48 {
  margin-left: 48%;
}

.baziprefix_60 {
  margin-left: 60%;
}

.baziprefix_72 {
  margin-left: 72%;
}

.baziprefix_84 {
  margin-left: 84%;
}

.baziprefix_33 {
  margin-left: 33.3%;
}

.baziprefix_66 {
  margin-left: 66.7%;
}

.baziprefix_50 {
  margin-left: 50%;
}

.suffix_33 {
  margin-right: 33.3%;
}

.bigfortunegrid {
  text-align: center;
  line-height: 14px;
  /* min-width: 375px; */
  width: auto !important;
  width: 470px;
}

.smallfortunegrid {
  min-height: 200px;
  height: auto !important;
  height: 200px;
  text-align: center;
  line-height: 14px;
  /* min-width: 375px; */
  width: auto !important;
  width: 470px;
  margin-top: 10px;
}

.bfcolumn {
}

.showsmallfortune {
  position: absolute;
  top: 200px;
  width: 100%;
  cursor: pointer;
  display: none;
}

.bigfortunerow,
.smallfortunerolling {
  min-width: 620px;
  width: auto !important;
  width: 620px;
}

.leadingfortune {
  width: 20%;
}

.rollingfortunes,
.brollingfortunes {
  overflow-x: auto;
  overflow-y: hidden;
  float: right;
  width: calc(100% - 8% - 72px);
}

.smallfortunerow {
  display: none;
}

.smallfortunerow.currentfortunerow {
  display: block;
}

.fortunecolumn {
  float: right;
  border: dotted 1px;
  min-height: 220px;
  height: auto !important;
  height: 220px;
  min-width: 60px;
  width: 8% !important;
  position: relative;
}

.fortunecolumn.bfcolumn {
  cursor: pointer;
}

.fdetail {
  position: relative;
  min-height: 60px;
  height: auto !important;
  height: 60px;
  width: auto !important;
}

.fsmalldetail {
}

.fortunemergeword {
  cursor: pointer;
}

.error {
  color: #e80c4d;
}

/* styles for validation helpers */
.field-validation-error {
  color: #e80c4d;
  font-weight: bold;
}

.info-container {
  max-width: 500px;
  padding: 8px;
  background-color: #f7f7f4;
}

.user-data > .group {
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
}

.group > span {
  min-width: 80px;
  white-space: pre-line;
}


.fortunecolumn [title] {
	border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
	border-radius:2px;
	position: relative;
}
.fortunecolumn [title] > * {
	user-select: none;
}
.fortunecolumn [title]:hover > * {
	user-select: auto
}
.fortunecolumn [title]:hover:after {
	position: absolute;
	top: 100%;
	right: -10%;
	content: attr(title);
	border: 1px solid rgba(0, 0, 0, 0.2);
	background-color: white;
	box-shadow: 1px 1px 3px;
	padding: 0.3em;
	z-index: 1;
    width: max-content;
}